export default () => ({
    gridlayout: null,
    categoryList: [],
    activeCategory: 'all',
    widgetList: {
        results: [],
        next: true,
        count: 0
    },
    widgetsEmpty: false,
    page: 0,
    widgets: [],
    dashboardList: [],
    active: null,
    ready: false,
    catalogVisible: false,
    searchWidget: ''
})